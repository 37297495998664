import React from 'react'
import ReactDOM from 'react-dom/client'

import reportWebVitals from './reportWebVitals'
import 'utils/formUtils/yupExtend.js'

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'app') {
    return import('./Root')
  } else if (process.env.REACT_APP_BUILD_TARGET === 'pay') {
    return import('./_pay-app/PayApp')
  } else {
    // default app
    return import('./Root')
    // return Promise.reject(new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET))
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

importBuildTarget().then(({ default: Environment }) => root.render(<Environment />))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
