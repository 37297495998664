import { getCurrentLanguage } from 'utils/i18nUtils'

export const fixedDecimals = (num: number, decimals: number): number => {
  const decimalsPower = 10 ** decimals
  return Math.round(num * decimalsPower) / decimalsPower
}

export const formatNumber = (num: any, options?: Intl.NumberFormatOptions) => {
  if (num == null) return null
  return (+num).toLocaleString(getCurrentLanguage(), options)
}

export function formatFloatToFixed(
  num: any,
  { maximumFractionDigits = 10, ...options }: Intl.NumberFormatOptions = {},
) {
  if (num == null) return 0
  const afterCommaLength = (+num)
    .toFixed(maximumFractionDigits)
    .replace(',', '.')
    .split('.')[0].length

  let maxDigits = maximumFractionDigits - afterCommaLength
  if (num < 10) {
    maxDigits += 1
  }

  return (+num).toLocaleString(getCurrentLanguage(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: Math.min(20, Math.max(0, maxDigits)),
    ...options,
  })
}

const fiatCurrencies = ['USD', 'EUR', 'GBP', 'NOK']

export const formatCurrency = (
  amount: any,
  { currency = 'EUR', ...options }: Intl.NumberFormatOptions = {},
) => {
  if (amount == null) {
    return undefined
  }
  currency = currency || 'EUR'
  const currencyUpperCase = currency.toUpperCase()
  const isFiat = fiatCurrencies.includes(currencyUpperCase)

  if (isFiat) {
    return (+amount).toLocaleString(getCurrentLanguage(), {
      currency: currencyUpperCase,
      style: 'currency',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options,
    })
  }

  return `${formatNumber(amount, {
    maximumFractionDigits: isFiat ? 2 : 8,
    ...options,
  })} ${currencyUpperCase}`
}
