import moment, { Moment } from 'moment'

export const formatDate = (date: any, format = 'MMM D, YYYY') => {
  if (date === null) return date
  return moment.utc(date).local().format(format)
}

export const formatDateRange = (
  date: Moment,
  range: 'from' | 'to' = 'from',
  format = 'YYYY-MM-DDTHH:mm:ss[Z]',
) => {
  if (date === null) return date
  date = range === 'from' ? date.startOf('day') : date.endOf('day')
  return date.utcOffset(0).format(format)
}
