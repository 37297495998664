export function capitalize(str?: string) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

export function formatInternalString(str: string, fallback: any = '') {
  if (!str) return fallback
  return capitalize(str.split('_').join(' '))
}

export const truncate = (source: string, size = 250) => {
  return source.length > size ? source.slice(0, size - 1) + '…' : source
}
